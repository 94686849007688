import {Router} from "@angular/router";
import {StorageService} from "../shared/services/storage.service";
import {HTTP_INTERCEPTORS, HttpInterceptor, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {BehaviorSubject, filter, finalize, Observable, switchMap, take, throwError} from "rxjs";
import {catchError, retryWhen} from "rxjs/operators";
import {ISettings} from "../models/interfaces/ISettings";
import {AppConstants} from "../appConstants";
import {AppRoutingConstant} from "../appRoutingConstants";
import {Injectable} from "@angular/core";
import {IRefreshReq} from "../models/request/IRefreshReq";
import {AppService} from "../services/app.service";
import { STORAGE } from "../models/enum/storage";


const TOKEN_HEADER_KEY = 'Authorization';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor( private localStorageService: StorageService,
              private appService: AppService,
              private router: Router) { }

  /**
 * Handles authentication errors encountered during HTTP requests.
 * @param authReq The HTTP request that encountered an error.
 * @param next The HTTP handler responsible for the request.
 * @param err An HttpErrorResponse object representing the error encountered.
 * @returns An Observable emitting data related to the HTTP request or error handling.
 * @description This function handles HTTP errors, particularly 401 (Unauthorized) and 403 (Forbidden) status codes,
 * and manages token refresh, access to stored settings, and redirects users accordingly.
 */                
  private handleAuthError(authReq: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      // let lastAuthReq: HttpRequest<any> = authReq;
      let setting: ISettings = this.localStorageService.getStorage(STORAGE.SETTINGS)
      setting.accessToken = '';
      this.localStorageService.setStorage(STORAGE.SETTINGS, setting);
      if(setting && setting.clientKey && setting.refreshToken) {
        const refreshReq: IRefreshReq = {
          accountKey: setting?.clientKey!,
          refreshToken: setting?.refreshToken!
        }
        return this.appService.refresh(refreshReq, false).pipe(
          switchMap(() => {
            let setting: ISettings = this.localStorageService.getStorage(STORAGE.SETTINGS);
            if (setting && setting.accessToken) {
              authReq = authReq.clone({ headers: authReq.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + setting.accessToken) });
            }
            return next.handle(authReq);
          }),
          catchError((error) => {
            return throwError(() => error);
          })
        );
      } else {
        this.localStorageService.removeAllFromStorage();
        this.router.navigateByUrl(AppRoutingConstant.path.signin);
      }
      return throwError(() => err);
    }
    if (err.status === 403) {
      this.localStorageService.removeAllFromStorage();
      this.router.navigateByUrl(AppRoutingConstant.path.landing);
      return throwError(() => err);
    }
    return throwError(() => err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq: HttpRequest<any> = req;
    let setting: ISettings = this.localStorageService.getStorage(STORAGE.SETTINGS);
    if (setting && setting.accessToken) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + setting.accessToken) });
    }
    if (setting && !setting.accessToken && setting.refreshToken) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + setting.refreshToken) });
    }
    return next.handle(authReq).pipe(catchError(x => this.handleAuthError(authReq, next, x)));
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

}

export const authInterceptorProviders: any = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];

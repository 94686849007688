<mat-toolbar class="toolbar" [ngClass]="isDetail() ? 'bg-transparent' : ''">
  <mat-toolbar-row>
  <mat-icon *ngIf="showIcon()" [ngClass]="isDetail() ? 'header-title-white' : ''" (click)="goBack()" style="position: absolute">keyboard_arrow_left</mat-icon>
  <div class="m-auto">
    <img *ngIf="!isDetail()" class="logo-img" src="assets/images/gift_vault.png"/>
    <div *ngIf="isDetail()" class="header-title">{{title}}</div>
  </div>
  </mat-toolbar-row>
</mat-toolbar>


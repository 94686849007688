import {Component, Input, Output, EventEmitter} from '@angular/core';
import {IVoucher} from "../../../models/interfaces/IVoucher";
import {STATES} from "../../../models/enum/states";




@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss']
})
export class InfiniteScrollComponent {
  @Input() arrayToShow: IVoucher[];
  @Input() showLoading: boolean;
  @Input() state: string;
  @Output() scrollDown = new EventEmitter<string>();
  @Output() eventClick = new EventEmitter<IVoucher>();
  array = [{
    title: 'test'
  }];
  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  modalOpen = false;
  constructor() {

  }



  onScrollDown() {
    this.scrollDown.emit('scroll');
  }

  click = (item: IVoucher) => {
    this.eventClick.emit(item);
  }

  trackByFn(index: number, item: IVoucher) {
    return item.id; // unique id corresponding to the item
  }

  public get stateType(): typeof STATES {
    return STATES;
  }

}

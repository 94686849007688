import {Component, Input} from '@angular/core';
import {IVoucher} from "../../../models/interfaces/IVoucher";
import {VOUCHERS} from "../../../models/enum/vouchers";

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {
  @Input() item : IVoucher;

  constructor() {

  }
  ngAfterViewInit() {

  }
  public get voucherType(): typeof VOUCHERS {
    return VOUCHERS;
  }
}

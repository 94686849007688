export enum PATHPARAMS {
  STATE = 'state',
  VOUCHERKEY = 'voucherKey'
}

/** Chiavi che identificano i path */
export enum PATHS {
   DETAILS = 'details',
   LIST = 'list',
   HOME = 'home',
   LANDING = 'landing',
   MYWALLET = 'mywalletid'
}

<div class="dialog-container">
<div mat-dialog-title class="dialog-title">{{'voucher.alert.title' | translate:data}}</div>
<div mat-dialog-content class="dialog-content">
  {{'voucher.alert.message' | translate}}
</div>
  <div mat-dialog-content class="dialog-sub-content">
    {{'voucher.alert.sub_message' | translate}}
  </div>
<div mat-dialog-actions class="dialog-button-container">

  <button mat-button color="primary" style="margin-right: 10px" (click)="closeDialog(false)">{{'voucher.alert.abort' | translate}}</button>
  <button mat-flat-button color="primary" style="margin-left: 10px"  (click)="closeDialog(true)" >{{'voucher.alert.confirm_button' | translate}}</button>
</div>
</div>

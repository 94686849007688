<ng-toast ></ng-toast>
<ngx-spinner
  [fullScreen]="true"
  bdColor="rgba(51,51,51,0.8)"
  color="#fff"
  size="medium"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<app-header *ngIf="showHeader()"></app-header>
<div class="main">
    <router-outlet></router-outlet>
</div>



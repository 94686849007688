<div class="search-results"
     infinite-scroll
     [infiniteScrollDistance]="scrollDistance"
     [infiniteScrollUpDistance]="scrollUpDistance"
     [infiniteScrollThrottle]="throttle"
     (scrolled)="onScrollDown()">
  <ng-container *ngFor="let item of arrayToShow; trackBy: trackByFn"  style="color: black">
    <ng-container *ngIf="item.state === stateType.USED">
    <app-list-item (click)="click(item)" [item]="item"> </app-list-item>
    </ng-container>
    <ng-container *ngIf="item.state === stateType.NEW || item.state === stateType.ASSIGNED">
      <app-list-new-item (click)="click(item)" [item]="item"> </app-list-new-item>
    </ng-container>
  </ng-container>
  <div style="text-align: center;" *ngIf="showLoading">
    <div class="loader"></div>
  </div>
</div>

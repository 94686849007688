import {Component, DestroyRef, inject} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AppRoutingConstant} from "../../../appRoutingConstants";
import {ISettings} from "../../../models/interfaces/ISettings";
import {AppConstants} from "../../../appConstants";
import {NgxSpinnerService} from "ngx-spinner";
import {StorageService} from "../../services/storage.service";
import {NgToastService} from "ng-angular-popup";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { STORAGE } from 'src/app/models/enum/storage';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-challange-token',
  templateUrl: './challange-token.component.html',
  styleUrls: ['./challange-token.component.scss']
})
export class ChallangeTokenComponent {
  challengeToken: string | null = '';
  destroyRef = inject(DestroyRef);
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly localStorageService: StorageService,
    private readonly spinner: NgxSpinnerService,
    private toast: NgToastService,
    private translate: TranslateService
  ) {
    this.spinner.show();
  }
  ngOnInit(): void {

  }

  /**
   * @memberof ChallangeTokenComponent
   * @function
   * @returns {void}
   * @description  This hook subscribes to route parameters, extracting the 'challengeToken' from the URL.
   * If a valid 'challengeToken' is present, it stores the token in the local storage for persistence and redirects the user to the sign-in page. If no 'challengeToken' is found, it displays an error toast message
   * and redirects the user to the landing page.
   */
  ngAfterViewInit() {
      this.route.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
        this.challengeToken = params['challengeToken'];
        if(this.challengeToken) {
          let setting : ISettings = {
            challengeToken: this.challengeToken
          };
          this.localStorageService.setStorage(STORAGE.SETTINGS, setting)
          this.router.navigateByUrl(AppRoutingConstant.path.signin);
        } else {
          this.toast.error({detail:"Attenzione",position: AppConstants.position, summary: this.translate.instant('toast.generic_error'),duration: AppConstants.duration});
          this.router.navigateByUrl(AppRoutingConstant.path.landing);
        }
      });
  }
}

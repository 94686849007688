import {Component, Input} from '@angular/core';
import {IVoucher} from "../../../models/interfaces/IVoucher";
import {VOUCHERS} from "../../../models/enum/vouchers";
import { STATES } from 'src/app/models/enum/states';

@Component({
  selector: 'app-list-new-item',
  templateUrl: './list-new-item.component.html',
  styleUrls: ['./list-new-item.component.scss']
})
export class ListNewItemComponent {
  @Input() item : IVoucher;
  state = STATES;
  constructor() {

  }
  ngAfterViewInit() {

  }
  public get voucherType(): typeof VOUCHERS {
    return VOUCHERS;
  }
}

import {Component, DestroyRef, inject} from '@angular/core';
import {AppRoutingConstant} from "../appRoutingConstants";
import {Router} from "@angular/router";
import {AppService} from "../services/app.service";
import {ILoginReq} from "../models/request/ILoginReq";
import {ISettings} from "../models/interfaces/ISettings";
import {AppConstants} from "../appConstants";
import {ILoginRes} from "../models/response/ILoginRes";
import {NgxSpinnerService} from "ngx-spinner";
import {StorageService} from "../shared/services/storage.service";
import {NgToastService} from "ng-angular-popup";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ErrorHandlerService} from "../shared/services/error-handler.service";
import { EnumAccountContextType} from "../models/enum/context";
import { TranslateService } from '@ngx-translate/core';
import { STORAGE } from '../models/enum/storage';


interface Country {
  value: string;
  viewValue: string;
}

const COUNTRY_OPTIONS: Country[] = [
  { value: "+39", viewValue: "+39 (IT)"},
  { value: "+33", viewValue: "+33 (FR)"},
  { value: "+49", viewValue: "+49 (GER)"}
];

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent {
  telephone: string = '';
  buttonText: string = '';
  setting!: ISettings;
  destroyRef = inject(DestroyRef);
  prefix = '';
  context = EnumAccountContextType;
  

  constructor(private readonly router: Router,
              private readonly appService: AppService,
              private localStorageService: StorageService,
              private toast: NgToastService,
              private errorHandler: ErrorHandlerService,
              private translate: TranslateService,
              private spinner: NgxSpinnerService) {
    this.spinner.hide();
    this.buttonText = COUNTRY_OPTIONS[0].viewValue;
    this.prefix = COUNTRY_OPTIONS[0].value;
  }


  countries = COUNTRY_OPTIONS;

  onSelect(country: Country) {
    this.buttonText = country.viewValue;
    this.prefix = country.value;
  }

  login = () => {
    if (this.telephone) {
      this.setting = this.localStorageService.getStorage(STORAGE.SETTINGS);
      if(this.setting) {
        this.spinner.show();
        const login: ILoginReq = {
          identifier: this.prefix + this.telephone,
          challengeToken: this.setting?.challengeToken ? this.setting?.challengeToken : '',
          context: this.context.CLIENT
        }
        this.appService.login(login, false).pipe(takeUntilDestroyed(this.destroyRef)).subscribe( {
          next: this.handleissuerRes.bind(this),
          error: this.handleIssuerError.bind(this)
        });
      } else {
        this.toast.error({detail:"Attenzione",position: AppConstants.position, summary: this.translate.instant('toast.generic_error'),duration: AppConstants.duration});
        this.router.navigateByUrl(AppRoutingConstant.path.landing);
      }
    }
  }

  handleissuerRes = (loginRes: ILoginRes) => {
    console.log(JSON.stringify(loginRes));
    if (loginRes) {
    this.setting.accessToken = loginRes.accessToken;
    this.setting.refreshToken = loginRes.refreshToken;
    this.setting.clientKey = loginRes.clientKey;
    this.setting.telephoneNumber = this.prefix + this.telephone;
    this.localStorageService.setStorage(STORAGE.SETTINGS, this.setting);
    this.spinner.hide();
    this.router.navigateByUrl(AppRoutingConstant.path.voucher);
    }
  }

  handleIssuerError = (error: any) => {
    this.errorHandler.errorHandler(error);
    this.spinner.hide();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AppRoutingConstant} from "./appRoutingConstants";
import {SigninComponent} from "./signin/signin.component";
import {ChallangeTokenComponent} from "./shared/components/challange-token/challange-token.component";
import {LandingComponent} from "./landing/landing.component";

const path: any = AppRoutingConstant.path;


const routes: Routes = [
  {
    path: path.signin,
    component: SigninComponent
  },
  {
    path: '',
    redirectTo: path.signin,
    pathMatch: 'full'
  },
  {
    path: path.challengeToken,
    component: ChallangeTokenComponent,
  },
  {
    path: path.landing,
    component: LandingComponent,
  },
  {
    path: path.voucher,
    loadChildren: () => import('./voucher/voucher.module').then((m: any) => m.VoucherModule)
  },
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

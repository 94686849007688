import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import { PATHS } from './models/enum/paths';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from 'src/environments/environment';

const firebaseConfig = environment.firebaseConfig;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gift-vault-user';
  loading: boolean = false;
  paths = PATHS;
  path = [this.paths.LIST, this.paths.HOME, this.paths.DETAILS, this.paths.MYWALLET];
  challengeToken: string | null = '';


  constructor(
    private readonly translate: TranslateService
  ) {
    this.translate.setDefaultLang('it');

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    const currentLang = this.translate.currentLang || 'it';
    this.translate.use(currentLang);
  }
  ngOnInit(): void {

  }


  showHeader: () => boolean = () => {
    return this.path.some(el => window.location?.href.includes(el));
  }

}

import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  private subjectHeaderTitle = new Subject();
  constructor() { }

  setHeaderTitle(title: string) {
    this.subjectHeaderTitle.next({ title: title });
  }

  getHeaderTitle(): Observable<any> {
    return this.subjectHeaderTitle.asObservable();
  }
}

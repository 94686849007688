import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {ILoginReq} from "../models/request/ILoginReq";
import {ILoginRes} from "../models/response/ILoginRes";
import {environment} from "../../environments/environment";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {NgxSpinnerService} from "ngx-spinner";
import {IRefreshReq} from "../models/request/IRefreshReq";
import {IRefreshRes} from "../models/response/IRefreshRes";
import {map} from "rxjs/operators";
import {ISettings} from "../models/interfaces/ISettings";
import {StorageService} from "../shared/services/storage.service";
import { STORAGE } from '../models/enum/storage';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpClient,
              private spinner: NgxSpinnerService,
              private localStorageService: StorageService) { }


  login(loginReq : ILoginReq, useMock = false): Observable<ILoginRes> {
    if(useMock){
      const loginRes : ILoginRes = {
        clientKey: "NXycDQKzlQ2HPyDC20O6j6",
        accessToken: "PX7QW3KqEAp.YKZz4kbFYhzztTk1ajIxMz.tLmMMy_QOc",
        refreshToken: "Us2urszDxFj.ZCWc-Hsc-8FyNEZ4wF4qzfj.o1pekiB6o"
      }
      return of<ILoginRes>(loginRes);
    }
    const jsonObjectT: JSON = loginReq as unknown as JSON;
      return this.http.post<ILoginRes>(environment.api.signin, jsonObjectT).pipe(map((response: ILoginRes | any) => {
        if (response) {
          return response;
        }
        return null;
      }));
  }

  refresh(refreshReq : IRefreshReq, useMock = false): Observable<IRefreshRes | undefined> {
    this.spinner.show();
    if(useMock){

    }
    const jsonObjectT: JSON = refreshReq as unknown as JSON;
    return this.http.post(environment.api.refresh, jsonObjectT).pipe(map((resp: any) => {
      let setting: ISettings = this.localStorageService.getStorage(STORAGE.SETTINGS)
      if(resp && resp?.refreshToken ) {
        setting.accessToken = resp.accessToken;
        setting.refreshToken = resp.refreshToken;
        this.localStorageService.setStorage(STORAGE.SETTINGS, setting);
        return resp;
      }
      return undefined;
      })
    );
  }
}

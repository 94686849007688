import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {VoucherRoutingConstant} from "../../../voucher/voucherRoutingConstrant";
import {Location} from '@angular/common';
import {ShareDataService} from "../../services/share-data.service";
import {Subscription} from "rxjs";
import {AppConstants} from "../../../appConstants";
import { PATHS } from 'src/app/models/enum/paths';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  path = [PATHS.LIST, PATHS.DETAILS, PATHS.MYWALLET];
  title: string = '';
  subscription: Subscription;
  constructor(
    private readonly router: Router,
    private location: Location,
    private readonly shareData: ShareDataService
  ) {
    this.subscription = this.shareData.getHeaderTitle().subscribe(value => {
      if(value) {
        this.title = value.title;
      }
    })
  }

  goBack = () => {
    this.location.back();
  }

  showIcon = () => {
    return this.path.some(el => this.router.url.includes(el));
  }

  isDetail = () => {
    return  this.router.url.includes(PATHS.DETAILS);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}

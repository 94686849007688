export const environment = {
    production: false,
    api: {
      signin: '/api/account/login',
      refresh: '/api/account/refresh',
      accounts: '/api/account/home',
      voucher: '/api/voucher/${voucherKey}',
      usedVoucher: '/api/voucher/${voucherKey}/used',
      queryvoucher: '/api/voucher',
      vouchercode: '/api/issuercode/${issuerCodeId}/code',
      longPull: '/api/voucher/${voucherKey}/pull'
    },
    loggerServiceActive: true,
    firebaseConfig : {
      apiKey: "AIzaSyBXOnb-I1VwXpHpGdQ6XmD56dvdy2yfWxU",
      authDomain: "fp8-ledger-dev.firebaseapp.com",
      projectId: "fp8-ledger-dev",
      storageBucket: "fp8-ledger-dev.firebasestorage.app",
      messagingSenderId: "207320605821",
      appId: "1:207320605821:web:c03781853657a42f6e7d14",
      measurementId: "G-4YB92YZGPW"
      }
  };
  
<div class="col-xs-12 text-center flex-fill flex-grow-1 w-80 signin-container">
  <div class="logo-container">
    <img class="logo" src="assets/images/gift_vault.png"/>
  </div>
  <p class="h1 mt-4">{{'welcomeMessage.title' | translate}}</p>
  <p class="h6 mt-3">{{'welcomeMessage.subtitle' | translate}}</p>


  <!-- #docregion mat-menu-trigger-for -->
  <button color="accent" mat-raised-button [matMenuTriggerFor]="menu" class="mt-5">
    {{buttonText}}<mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
  </button>
  <!-- #enddocregion mat-menu-trigger-for -->
  <mat-menu #menu="matMenu" >
    <button (click)="onSelect(country)" *ngFor="let country of countries" mat-menu-item>
      {{country.viewValue}}
    </button>
  </mat-menu>

  <form class="example-form mt-2">
    <mat-form-field class="example-full-width">
      <mat-icon matPrefix>smartphone</mat-icon>
      <input  [(ngModel)]="telephone" [ngModelOptions]="{standalone: true}" type="tel" matInput placeholder="Inserire numero di telefono" class="custom-input">
    </mat-form-field>
  </form>
  <div class="button-container">
    <button mat-raised-button color="primary" [disabled]="!telephone || telephone.length < 1" (click)="login()" class="custom-button">{{'login.button' | translate}}</button>
  </div>
</div>


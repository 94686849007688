import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  @Input() endTime!: number; // Input for the end time duration in seconds
  @Output() expired = new EventEmitter<boolean>();
  timeLeft: number = 0;
  seconds: number = 0;
  private countdownSubscription!: Subscription;

  ngOnInit() {
    this.startCountdown();
  }

  startCountdown() {
    const source = timer(0, 1000); // Emit a value every second
    this.countdownSubscription = source.subscribe(() => {
      this.seconds = this.seconds + 1;
      this.timeLeft = Math.max(0, this.endTime - this.seconds); 
      this.timeLeft < 1 ? this.expired.emit(true) : null;
    });
  }

  ngOnDestroy() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }
}

<div class="item-container ">
  <div class="left-container">
    <img class="item-img" src="{{item.imageLogo}}">
  </div>
  <div class="center-container">
    <div class="row">
      <div class="col item-title">
        {{item.extendedVoucherData!.description!}}
      </div>
    </div>
    <div class="row">
      <div class="col item-subtitle">
          {{'voucher.detail.expiredWithoutInterpolation' | translate}} {{item.usedDate}}
      </div>
    </div>
  </div>
  <div class="right-container item-amount-container">
    <div class="item-amount">
      {{item.extendedVoucherData!.amount! | currency: item.extendedVoucherData!.currency! : 'symbol' : '1.0-0'}}
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {InfiniteScrollComponent} from "./components/infinite-scroll/infinite-scroll.component";
import { ListItemComponent } from './components/list-item/list-item.component';
import { ChallangeTokenComponent } from './components/challange-token/challange-token.component';
import { DialogComponent } from './components/dialog/dialog.component';
import {MatButtonModule} from "@angular/material/button";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpLoaderFactory} from "../app.module";
import {HttpClient} from "@angular/common/http";
import { ListNewItemComponent } from './components/list-new-item/list-new-item.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';



@NgModule({
  declarations: [
    HeaderComponent,
    InfiniteScrollComponent,
    ListItemComponent,
    ChallangeTokenComponent,
    DialogComponent,
    ListNewItemComponent,
    CountdownTimerComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    InfiniteScrollModule,
    MatButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CommonModule,
  ],
  exports: [
    HeaderComponent,
    InfiniteScrollComponent,
    ListItemComponent,
    CountdownTimerComponent
  ]
})
export class SharedModule { }

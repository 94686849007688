<div class="item-container ">
  <div class="left-container">
    <div class="left-container">
      <img class="item-img" src="{{item.imageLogo}}">
    </div>
  </div>
  <div class="center-container">
    <div class="row">
      <div class="col item-title">
        {{item.extendedVoucherData.description!}}
      </div>
    </div>
    <div class="row">
      <div class="col item-subtitle">
        <ng-container *ngIf="item && (item.state === state.ASSIGNED || item.state === state.NEW)">
          {{'voucher.detail.expireWithoutInterpolation' | translate}} {{item.extendedVoucherData.expiry}}
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col item-note">
        {{'voucher.detail.noteWithoutInterpolation' | translate}}
      </div>
    </div>
  </div>
  <div class="right-container item-amount-container">
    <div class="item-amount">
      {{item.extendedVoucherData.amount! | currency: item.extendedVoucherData.currency! : 'symbol' : '1.0-0'}}
    </div>
  </div>
</div>
